.infoSection {
	background-color: #333;
	color: rgba(255, 255, 255, 0.75);
	background-attachment: fixed, fixed;
	background-image: url(../../assets/images/overlay1.png),
		url(../../assets/images/sectionBackground.jpg);
	background-size: auto, cover;

	display: flex;
	flex-direction: row;
	color: #000;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.infoContent {
	margin-top: 5vh;
	margin-bottom: 3vh;
	width: 100vw;
	object-fit: contain;
}

.infoHeader {
	color: #fff;
	font-size: 36px;
	margin: 0;
	margin-left: 3vw;
	margin-bottom: 10px;
	padding-bottom: 10px;
	font-weight: 200;
	text-align: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	width: 100vw;
}

.infoText {
	color: #fff;
	margin: 0 0 0 3vw;
	text-align: center;
	font-size: 18px;
	font-weight: 200;
	line-height: 36px;
	padding: 0 20px;
}

a {
	color: #fff;
}

a:hover {
	color: #fee3b5;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.infoSection {
		padding-right: 25px;
	}

	.infoContent {
		margin-top: 15vh;
		margin-bottom: 15vh;
		width: 45vw;
	}

	.infoHeader {
		text-align: left;
		width: 35vw;
	}

	.infoText {
		text-align: left;
	}
}
