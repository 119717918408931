.cartContainer {
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.cart {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100vw;
}

.checkoutItemContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 1px solid #ccc;
	padding: 5px;
}

.checkoutItemContainer:nth-child(2) {
	border-top: 1px solid #ccc;
}

.checkoutItemDetailsContainer {
	display: flex;
	flex-direction: row;
}

.checkoutItemDetailsContainer .title {
	font-size: 17px;
	font-weight: 700;
	margin: 0;
	padding: 0;
}

.checkoutItemDetailsContainer .inStock {
	font-size: 14px;
	margin: 0;
	padding: 0;
}

.checkoutItemDetails {
	margin-left: 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.checkoutItemContainer img {
	object-fit: contain;
	height: 100px;
	width: 100px;
}

.selectBox {
	background: #e7e9ec;
	border-radius: 3px;
	border-color: #adb1b8 #a2a6ac #8d9096;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	display: inline-block;
	padding: 0;
	text-align: center;
	text-decoration: none !important;
	vertical-align: middle;
	height: 30px;
}

.deleteLink {
	color: #fff;
	width: 75px;
	background: red;
}

.deleteLink:hover {
	background: darkred;
	cursor: pointer;
}

.checkoutForm {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 15px;
	margin-bottom: 55px;
	width: 100vw;
}

.dateForms {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 10px;
	width: 75vw;
	margin-left: auto;
	margin-right: auto;
}

.datePicker {
	background: #fff;
	border-radius: 3px;
	border-color: #adb1b8 #a2a6ac #8d9096;
	border-style: solid;
	border-width: 1px;
	cursor: pointer;
	display: inline-block;
	padding: 0;
	margin-left: 10px;
	text-align: center;
	text-decoration: none !important;
	vertical-align: middle;
	height: 30px;
}

.checkoutBtnWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.checkoutBtn {
	margin-top: 15px;
	margin-bottom: 0;
	width: 45vw;
}

button {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #e7e9ec;
	border: 1px solid #ccc;
	border-radius: 4px;
}

button:hover {
	background: #9fa0a1;
	color: #fff;
	cursor: pointer;
}

.backLink {
	text-decoration: underline;
}

.backLink:hover {
	margin: 0;
	padding: 0;
	cursor: pointer;
	color: #f59f27;
}

/* Desktop */
@media only screen and (min-width: 650px) {
	.cart {
		width: 75vw;
	}

	.checkoutForm {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-top: 15px;
		margin-bottom: 55px;
		width: 75vw;
		line-height: 30px;
	}

	.dateForms {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding-top: 10px;
		width: 75vw;
		margin-left: auto;
		margin-right: auto;
	}

	.checkoutBtn {
		margin-top: 15px;
		margin-bottom: 0;
		width: 135px;
	}

	.checkoutBtnWrapper {
		justify-content: flex-end;
		width: 75vw;
	}
}
