.addEditUserContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
}

.addEditUserColumn {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 200px;
	margin: 5px;
	justify-content: center;
	align-items: center;
}

.AddEditUserHeader {
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 5px;
}

.btn {
	width: 100px;
	height: 35px;
}

.textInput {
	border-radius: 3px;
	border: 1px solid #cccccc;
	padding: 5px;
	font-size: 15px;
	min-height: 18px;
	min-width: 100%;
	max-width: 100%;
}

.textInput.disabled {
	background: #eee;
	color: #000;
	cursor: no-drop;
}

select {
	border-radius: 3px;
	border: 1px solid #cccccc;
	padding: 5px;
	font-size: 15px;
	min-height: 18px;
	min-width: 100%;
	max-width: 100%;
	height: 30px;
}

div.rt-tr {
	align-items: center;
}

.fa-check {
	color: green;
	font-size: 20px;
}

.fa-times {
	font-size: 20px;
	color: darkred;
}
