/* Style the tab */
.tab {
	overflow: hidden;
	border: 1px solid #ccc;
	background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
	background-color: inherit;
	float: left;
	border: none;
	outline: none;
	cursor: pointer;
	padding: 14px 16px;
	transition: 0.3s;
	font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
	background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
	background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
	display: none;
	padding: 6px 12px;
	border: 1px solid #ccc;
	border-top: none;
}

.approveButton {
	background-color: #63a020;
	color: #fff;
	margin-right: 5px;
}
.approveButton:hover {
	background-color: #4c7a1a;
}

.deleteButton {
	background-color: #c9091e;
	color: #fff;
}
.deleteButton:hover {
	background-color: #9b0b1b;
}
