.clubSection {
	background: #fee3b5;
	display: flex;
	flex-direction: column;
	color: #000;
	padding-bottom: 10vh;
}

.clubRow {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	flex-wrap: wrap;
}

.clubConent {
	width: 100vw;
	padding-top: 10vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.clubImage {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.boardList {
	padding: 0;
	margin: 0 0 0 3vw;
	list-style: none;
	text-align: left;
	font-size: 16px;
	font-weight: 200;
	line-height: 1.6em;
}

.boardList li {
	padding-bottom: 8px;
	text-align: center;
	padding: 0 15px;
}

.boardList li strong {
	font-weight: 600;
}

.clubHeader {
	font-size: 36px;
	margin: 0;
	margin-left: 3vw;
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-weight: 200;
	text-align: center;
	border-bottom: 1px solid rgba(144, 144, 144, 0.5);
	width: 100vw;
}

.clubConent p {
	margin: 0 0 0 3vw;
	text-align: center;
	font-size: 18px;
	font-weight: 200;
	line-height: 1.6em;
	padding: 0 15px;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.clubConent {
		width: 45vw;
	}

	.clubHeader {
		width: 20vw;
	}

	.boardList {
		text-align: left;
	}

	.boardList li {
		text-align: left;
		padding: 5px;
	}

	.clubConent p {
		text-align: left;
		padding: 5px;
	}
}
