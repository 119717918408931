.sponsorSection {
	background-color: #333;
	color: rgba(255, 255, 255, 0.75);
	background-attachment: fixed, fixed;
	background-image: url(../../assets/images/overlay1.png),
		url(../../assets/images/sectionBackground.jpg);
	background-size: auto, cover;

	display: flex;
	flex-direction: column;
	color: #000;
	padding-bottom: 3vh;
	align-items: center;
	justify-content: center;
}

.sponsorHeader {
	text-align: center;
	color: #fff;
	font-size: 1.8em;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 200;
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
	width: 100vw;
}

.sponsorBody {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	width: 100vw;
}

.sponsorImageContainer {
	margin: 2px 5px;
	width: 20vw;
}

.sponsorImage {
	object-fit: contain;
	object-position: center;
	width: 100%;
	height: 75px;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.sponsorSection {
		padding-bottom: 10vh;
	}

	.sponsorHeader {
		width: 15vw;
	}

	.sponsorBody {
		width: 80vw;
	}

	.sponsorImageContainer {
		margin: 25px;
		width: 15vw;
		height: 25vh;
	}

	.sponsorImage {
		object-fit: contain;
		object-position: center;
		width: 100%;
		height: 135px;
	}
}
