/* Mobile */
.logo {
	height: 80vmin;
	margin-top: 5vh;
}

.welcome {
	background-color: #cc8400;
	color: rgba(255, 255, 255, 0.75);
	background-attachment: fixed, fixed, fixed;
	background-image: url(../../assets/images/overlay2.png), url(../../assets/images/overlay3.svg),
		linear-gradient(45deg, #ffc966 5%, #ffa500 30%, #cc8400);
	background-position: top left, center center, center center;
	background-size: auto, cover, cover;

	height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	align-content: center;
	padding-top: 55px;
	color: white;
}

.welcomeText {
	font-size: 28px;
	margin: 0;
	padding-top: 10px;
	font-weight: 300;
}

.subWelcomeText {
	font-size: 36px;
	margin: 0;
	font-weight: 400;
}

.yeeyeeContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.yeeyeeBoxContainer {
	padding-top: 25px;
	animation-name: increasePadding;
	animation-duration: 10s;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

@keyframes increasePadding {
	0% {
		padding-top: 90px;
	}
	100% {
		padding-top: 25px;
	}
}

.yeeyee {
	padding: 11px 15px;
	letter-spacing: 0.05em;
	border: 1px solid #fff;
	border-radius: 3px;
	font-size: 16px;
	align-self: center;
	text-align: center;
	font-weight: 600;
}

.yeeyee:hover {
	background-color: #fff;
	color: #cc8400;
	cursor: pointer;
}

.yeeyeeLine {
	border: 1px solid #fff;
	width: 0px;
	height: 30vh;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.logo {
		height: 60vmin;
		margin-top: 0;
	}
}
