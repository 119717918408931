.footer {
	background-color: #cc8400;
	color: rgba(255, 255, 255, 0.75);
	background-attachment: fixed, fixed, fixed;
	background-image: url(../../assets/images/overlay2.png), url(../../assets/images/overlay3.svg),
		linear-gradient(45deg, #ffc966 5%, #ffa500 30%, #cc8400);
	background-position: top left, center center, center center;
	background-size: auto, cover, cover;

	color: white;
	padding-top: 15px;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
}

.icons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50vw;
	align-self: center;
	margin-bottom: 5px;
}

.icon {
	font-size: 2em;
	text-decoration: none;
}

.icon:hover {
	cursor: pointer;
	opacity: 0.7;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.footer {
		height: 35vh;
	}

	.icons {
		width: 15vw;
		margin-bottom: 20px;
	}
}
