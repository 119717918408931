.lockerContainer {
	display: flex;
	width: 100vw;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-self: center;
	margin-bottom: 45px;
}

.itemContainer {
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 2px solid #ccc;
	padding: 5px;
}

.itemImageContainer {
	padding-top: 10px;
	position: relative;
	width: 100%;
	height: 50%;
	z-index: 1;
}

.inCartOverlay {
	height: 100%;
	z-index: 1;
	position: relative;
}

.inCartIcon {
	position: absolute;
	top: 25%;
	left: 32%;
	font-size: 75px !important;
	color: green;
	z-index: 4;
}

.itemImage {
	width: 25vw;
	height: 100%;
	object-fit: contain;
}

.itemTitle {
	font-size: 18px;
	font-weight: 500;
	padding-top: 8px;
	padding-left: 0px;
	padding-right: 0px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.itemAvailability {
	font-size: 15px;
	font-weight: 400;
	padding-top: 5px;
}

.itemIconsContainer {
	padding-top: 15px;
	padding-right: 15px;
}

.itemIcon {
	padding-top: 6px;
	padding-bottom: 3px;
	padding-left: 8px;
	padding-right: 0px;
	background: #f59f27;
	font-size: 20px;
	line-height: 25px;
	border: none;
	color: #fff;
	width: 25px;
	height: 25px;
	border-radius: 4px;
}

.plus {
	border-top-left-radius: 4px;
}

.minus {
	border-top-left-radius: 4px;
}

.info {
	border-top-right-radius: 4px;
}

.plus:hover {
	background: green;
	cursor: pointer;
}

.minus:hover {
	background: #ac0202;
	cursor: pointer;
}

.info:hover {
	background: #c27e1f;
	cursor: pointer;
}

/* Desktop */
@media only screen and (min-width: 650px) {
	.lockerContainer {
		width: 90vw;
	}

	.itemContainer {
		width: 200px;
		height: 200px;
		border: 2px solid #ccc;
		border-radius: 4px;
		margin-top: 15px;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: space-between;
		padding: 0px;
	}

	.itemIcon {
		padding-top: 10px;
		padding-left: 15px;
		padding-right: 15px;
		background: #f59f27;
		font-size: 20px;
		line-height: 45px;
		border: none;
		color: #fff;
		width: 25px;
		height: 55px;
	}

	.itemIconsContainer {
		padding: 0;
		position: relative;
		bottom: 52px;
		height: 13px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		z-index: 10;
	}

	.itemImage {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	.itemTitle {
		padding-left: 5px;
		padding-right: 5px;
	}
}
