.instagramSection {
	background: #ffffff;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	padding: 20px 0;
}

.instagramSection:hover {
	cursor: pointer;
}

.instagramImg {
	width: 100px;
	height: 100px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.instagramsectionOverlay {
	width: 100vw;
	height: 650px;
	background-color: #000;
	opacity: 0.45;
	text-align: center;
	z-index: 95;
	position: absolute;
}

.instagramLogoContainer {
	width: 100vw;
	text-align: center;
	z-index: 99;
	position: absolute;
	background: #fee3b5;
}

.instagramLogo {
	width: 100vw;
	background-size: cover;
	background-position: center;
}

/* Desktop */
@media only screen and (min-width: 768px) {
	.instagramSection {
		padding: 0;
	}

	.instagramImg {
		width: 25vw;
		height: 325px;
	}

	.instagramLogo {
		width: 45vw;
		padding: 45px;
	}
}
